<template>
  <div class="Uploadimg">
    <el-upload
      action=""
      :http-request="uploadhttp"
      list-type="picture-card"
      :drag="drag"
      :multiple="multiple"
      :limit="limit"
      :before-upload="beforeAvatarUpload"
      :on-exceed="onexceed"
      :on-change="onChange"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="vueCroppertype">
      <vueCropper
        style="width: 100%; height: 60vh"
        ref="cropper"
        :img="options.oneurl"
        :autoCrop="options.autoCrop"
        :fixedBox="options.fixedBox"
        :canMoveBox="options.canMoveBox"
        :fixed="options.fixed"
        :fixedNumber="[imgall.width, imgall.height]"
        :centerBox="options.centerBox"
        @realTime="realTime"
      >
      </vueCropper>
      <el-button
        type="primary"
        class="vueCroppersubbtn"
        @click="finish"
        :loading="loading"
        >裁切并上传</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["imgall"],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      vueCroppertype: false,
      disabled: false,
      limit: 10, //最多可上传X张图片
      drag: false, //是否拖拽上传
      multiple: false, //是否支持多选文件
      userinfo: {},
      textce: "单图",
      previews: {},
      options: {
        oneurl: "", //原图片路径
        fixed: true, //是否开启截图框宽高固定比例
        autoCrop: true, //默认生成截图框
        fixedBox: false, //固定截图框大小
        canMoveBox: false, //截图框不能拖动
        centerBox: true, //截图框被限制在图片里面
        outputSize: 10,
        infoTrue: true,
      },
      falsedata: {
        file: {},
      },
      caijianover: false,
      loading: false,
      filenameall: "",
    };
  },
  methods: {
    uploadhttp(e) {
      var that = this;
      if (this.caijianover) {
        this.$message("努力上传中，请稍等！");
        let file = new FormData();
        file.append("file", e.file);
        // file.append("d_id", this.imgall.d_id);
        // file.append("d_id", "18");
        var imgs = {
          name: e.file.name,
          src: "",
        };
        var upurl = "";
        if (that.imgall.name == "complex") {
          upurl = "atlasImg";
        } else {
          upurl = "upImg";
        }
        // oss直传
        //oss直传配置
        const OSS = require("ali-oss");
        const client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: "LTAI5tMFK57KYsZ1zs4fczsL",
          accessKeySecret: "xeH7iJrLc4YpQa0tZAoxydKXh8AO7Q",
          bucket: "shijian-oss",
        });

        //oss直传配置

        //文件存储路径
        let date = new Date();
        let dateMonth = date.getMonth() + 1;
        if (dateMonth < 10) {
          dateMonth = "0" + dateMonth + "";
        }
        let dateall =
          "" + date.getFullYear() + "" + dateMonth + "" + date.getDate() + "";
        let date_s = Date.parse(date);
        var userinfo = JSON.parse(localStorage.getItem("userinfo"));
        var filename = date_s +''+ userinfo.userid;
        var length1 = this.filenameall.lastIndexOf("."); //取到文件名开始到最后一个点的长度
        var length2 = this.filenameall.length; //取到文件名长度
        var houzhui = this.filenameall.substring(length1 + 1, length2); //截
        filename = filename + "." + houzhui;
        let storeAs = "news_img/" + dateall + "/" + filename + "";
        //文件存储路径
        client
          .put(storeAs, e.file)
          .then((res) => {
            console.log(res)
            //下面是如果对返回结果再进行处理，根据项目需要
            that.loading = false;
            that.caijianover = false;
            that.vueCroppertype = false;
            that.$message("上传成功！");
            if (that.imgall.name == "complex") {
              imgs.src ='https://img.hozoin.cn/'+ res.name;
              that.$emit("uploadimgheji", imgs);
            } else {
              that.$emit("uploadimg", 'https://img.hozoin.cn/'+ res.name);
            }
          })
          .catch((err) => {
            that.$message(err);
          });
        // oss直传

        return false;
        //接口请求  废弃
        that
          .$axios({
            headers: {
              "X-CSRF-TOKEN": that.userinfo.token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            // responseType:"blob",
            url: "/api/UploadFile/" + upurl,
            method: "post",
            data: file,
          })
          .then((res) => {
            if (res.data.code == 1) {
              that.loading = false;
              that.caijianover = false;
              that.vueCroppertype = false;
              if (that.imgall.name == "complex") {
                imgs.src = res.data.result;
                that.$emit("uploadimgheji", imgs);
                that.$message(res.data.messages);
              } else {
                that.$emit("uploadimg", res.data.result);
                that.$message(res.data.messages);
              }
            }
          });
        //接口请求  废弃
      } else {
        that.$message("请进行图片裁切");
      }
    },
    handleRemove(file) {
      // 删除上传图片执行
    },
    handlePictureCardPreview(file) {
      //缩略图 大图显示
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // 下载执行
    },
    onexceed(e) {
      // 上传文件大于最大可上传数执行
      this.$message.error("最多可上传 " + this.limit + " 张图片");
    },
    beforeAvatarUpload(file) {
      this.filenameall = file.name;
      var that = this;
      let isLt1M = file.size / 1024 / 1024 < 20;
      if (!isLt1M) {
        that.$message.error("上传图片大小不能超过 20MB!");
        return isLt1M;
      }
      if (that.imgall.name == "complex") {
        // 上传图集不用裁切处理
        let isJPG =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG/png/gif 格式!");
          return isJPG;
        }
        that.caijianover = true;
      } else {
        //图片裁切
        let isJPG = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG/png/ 格式!");
          return isJPG;
        }
        that.vueCroppertype = true;
      }

      return isLt1M;
    },
    onChange(e) {
      this.options.oneurl = e.url;
    },
    realTime(data) {
      this.previews = data;
    },
    finish() {
      var that = this;
      this.loading = true;
      this.$refs.cropper.getCropBlob((data) => {
        that.falsedata.file = data;
        this.caijianover = true;
        that.uploadhttp(that.falsedata);
      });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
  },
};
</script>
